


















































































import { Component, Vue } from "vue-property-decorator";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-vue";
import { VacationDto, AllVacationFilter, VCR, AllVCRFilter, VCRStatus } from "@/dto/payroll/VacationDTO";
import { IEmployment } from "@/dto/payroll/IEmployment";
import { WorkspaceType } from "@/dto/auth/Workspace";
import VacationRequestDetails from "@/components/payroll/vacation/VacationRequestDetails.vue";
import moment from "moment";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import { processError } from "@/utils/ComponentUtils";
import EmploymentService from "@/services/EmploymentService";
import { EmploymentFilter } from "@/dto/payroll/Filters";
import { CounterpartyType } from "@/constants/CounterpartyType";
import { EmploymentStatus } from "@/components/payroll/business/employments/contractors/Contractor";
import VacationRequestCreationModal from "@/components/payroll/vacation/VacationRequestCreationModal.vue";
import { EventBus } from "@/utils/EventBus";

@Component<VacationList>({
  components: {
    DayPilotScheduler,
    VacationRequestDetails,
  },
  computed: {
    WorkspaceType() {
      return WorkspaceType;
    }
  },
  watch: {
    createMode(n) {
      if (n) {
        this.config = { ...this.config, onTimeRangeSelected: args => {
          args.control.clearSelection();
          this.checkEmptyEvent();
          const e = new DayPilot.Event({
            start: args.start,
            end: args.end,
            id: DayPilot.guid(),
            resource: args.resource,
            item: {},
            text: '',
            html: `<div class="event-content__col"></div>`,
            fontColor: '#FFFFFF',
            backColor: '#D1D1D1',
            borderRadius: '32px',
          });
          args.control.events.add(e);
        }}
      } else {
        this.config = { ...this.config, onTimeRangeSelected: undefined };
        this.checkEmptyEvent();
      }
    }
  }
})
export default class VacationList extends Vue {

  $refs!: {
    schedulerRef: DayPilotScheduler,
  }

  private config: DayPilot.SchedulerConfig = {
    timeHeaders: [{groupBy: "Day", format: "d", height: 68}],
    scale: "Day",
    days: DayPilot.Date.today().daysInMonth(),
    startDate: DayPilot.Date.today().firstDayOfMonth(),
    resources: [],
    events: [],
    cellWidth: 68,
    durationBarVisible: false,
    cornerText: 'Days',
    // floatingEvents: false,
    rowHeaderColumnHeaderHeight: 68,
    rowHeaderWidthAutoFit: true,
    eventHeight: 68,
    eventMinWidth: 68,
    eventPadding: '22px 32px',
    eventMoveHandling: 'Disabled',
    eventResizeHandling: 'Disabled',
    eventClickHandling: 'Bubble',
    eventHoverHandling: 'Bubble',
    eventRightClickHandling: 'Disabled',
    bubble: new DayPilot.Bubble({
      hideOnClick: false,
      hideOnHover: false,
      showAfter: 0,
      hideAfter: 0.1,
      onDomAdd: (args) => {
        let component;
        if (this.isVCR(args.source.data.item)) {
          component = new VacationRequestDetails({ propsData: { vacationRequest: args.source.data.item } }).$mount();
        } else if (this.isVacationDto(args.source.data.item)) {
          component = new VacationRequestDetails({ propsData: { vacation: args.source.data.item } }).$mount();
        } else {
          component = new VacationRequestCreationModal({
              propsData: {
                employments: [this.Employments().find(item => item.id === args.source.data.resource)],
                callback: this.updateScheduler,
                periodFromCalendar: {
                  startDate: args.source.data.start.toString('yyyy-MM-dd'),
                  endDate: args.source.data.end.addDays(-1).toString('yyyy-MM-dd')
                },
              }
            }).$mount();
        }
        args.element = component.$el;
      }
    })
  };

  private vacations: Array<VacationDto> = [];
  private vacationsFilter = new AllVacationFilter();
  private vacationRequests: Array<VCR> = [];
  private vacationRequestsFilter = new AllVCRFilter({ status: VCRStatus.REQUESTED });
  private employments: Array<IEmployment> = [];
  private employmentsFilter = new EmploymentFilter({
    types: ["STAFF", "FOREIGN"],
    contractorType: CounterpartyType.PERSON,
    status: [EmploymentStatus.ACTIVE, EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS]
  });
  private years: Array<number> = [];
  private selectedMonth = moment(new Date()).month();
  private selectedYear = moment(new Date()).year();
  private totalPages = 1;
  private createMode = false;

  mounted(): void {
    EventBus.$on('changeStatusVacationRequest', () => this.updateScheduler());
    this.updateScheduler();
  }

  beforeDestroy(): void {
    EventBus.$off('changeStatusVacationRequest');
  }

  checkEmptyEvent(): void {
    if (this.config.events) {
      if (!this.isVCR(this.config.events[this.config.events.length - 1].item) && !this.isVacationDto(this.config.events[this.config.events.length - 1].item)) {
        this.config.events.pop();
      }
    }
  }

  private async updateScheduler() {
    await this.loadEmployments();
    await this.loadVacations();
    await this.loadVacationRequests();
    this.loadResources();
    this.loadEvents();
  }

  private async loadVacations() {
    Application.startLoading();
    if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
      this.vacationsFilter.employmentsId = this.employments.map(item => item.id!);
    }
    if (this.$wss.getCurrent.type === WorkspaceType.COMPANY) {
      this.vacationsFilter.employerId = this.id;
    }
    const startDateFilter = this.config.startDate?.toString();
    const endDateFilter = moment(startDateFilter).endOf('month');
    this.vacationsFilter.startDate = moment(startDateFilter).format('YYYY-MM-DD');
    this.vacationsFilter.endDate = moment(endDateFilter).format('YYYY-MM-DD');
    return VacationService.getAllVacationsByFilter(this.vacationsFilter).then(
      res => {
        this.vacations = res.data;
        Application.stopLoading();
      },
      err => processError(err, this)
    )
  }

  private async loadVacationRequests() {
    Application.startLoading();
    if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
      this.vacationRequestsFilter.employmentsId = this.employments.map(item => item.id!);
    }
    if (this.$wss.getCurrent.type === WorkspaceType.COMPANY) {
      this.vacationRequestsFilter.employerId = this.id;
    }
    const startDateFilter = this.config.startDate?.toString();
    const endDateFilter = moment(startDateFilter).endOf('month');
    this.vacationRequestsFilter.startDate = moment(startDateFilter).format('YYYY-MM-DD');
    this.vacationRequestsFilter.endDate = moment(endDateFilter).format('YYYY-MM-DD');
    return VacationService.getAllVacationRequestsByFilter(this.vacationRequestsFilter).then(
      res => {
        this.vacationRequests = res.data;
        Application.stopLoading();
      },
      err => processError(err, this)
    )
  }

  private async loadEmployments() {
    try {
      Application.startLoading();
      if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
        this.employmentsFilter.detailsId = this.id;
      } else {
        this.employmentsFilter.employerId = this.id;
      }
      const res = await EmploymentService.getPageByFilter<IEmployment>(this.employmentsFilter);
      this.employments = res.data.data;
      this.totalPages = res.data.countOfPages;
    } catch (err) {
      processError(err, this);
    } finally {
      Application.stopLoading();
    }
  }

  private loadResources() {
    if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
      this.config.resources = this.employments.map(item => {
        return { id: item.id!, name: item.employer.name };
      });
    } else {
      this.config.resources = this.employments.map(item => {
        return { id: item.id!, name: item.details.name! };
      });
    }
  }

  private loadEvents() {
    this.config.events = [...this.eventsVacations, ...this.eventsVacationRequests];
  }

  private isVCR(item: VCR | VacationDto): item is VCR {
    return 'requester' in item;
  }

  private isVacationDto(item: VCR | VacationDto): item is VacationDto {
    return 'duration' in item;
  }

  private previousMonth() {
    this.config.startDate = (this.config.startDate as DayPilot.Date).addMonths(-1);
    this.config.days = this.config.startDate.daysInMonth();
    this.selectedMonth = this.config.startDate.getMonth();
    this.selectedYear = this.config.startDate.getYear();
    this.updateScheduler();
  };

  private nextMonth() {
    this.config.startDate = (this.config.startDate as DayPilot.Date).addMonths(1);
    this.config.days = this.config.startDate.daysInMonth();
    this.selectedMonth = this.config.startDate.getMonth();
    this.selectedYear = this.config.startDate.getYear();
    this.updateScheduler();
  };

  private getDataForScheduler() {
    this.config.startDate = new DayPilot.Date(`${this.selectedYear}-${moment(this.selectedMonth + 1, 'M').format('MM')}-01`);
    this.config.days = this.config.startDate.daysInMonth();
    this.updateScheduler();
  }

  private changePage(page: number) {
    this.employmentsFilter.pageNumber = page - 1;
    this.updateScheduler();
  }

  private showVacationRequestCreationModal() {
    this.$modal.show(VacationRequestCreationModal,
      {
        employments: this.employments,
        callback: this.updateScheduler,
      });
  }

  Employments(): Array<IEmployment> {
    return this.employments;
  }

  get eventsVacationRequests(): Array<DayPilot.EventData> {
    return this.vacationRequests.map(item => {
      return {
        id: item.id!,
        resource: item.requester.id!,
        item,
        start: new DayPilot.Date(item.vacation.startDate),
        end: new DayPilot.Date(item.vacation.endDate).addDays(1),
        text: '',
        html: ` <div class="event-content__container">
                  <div class="event-content__left-col">
                     ${this.$wss.getCurrent.type === WorkspaceType.PERSON ? item.requester.employer.name : item.requester.details.name} • ${item.vacation.startDate} - ${item.vacation.endDate} • ${item.vacation.paid ? 'Payed' : 'Not payed'} • ${item.vacation.duration}
                  </div>
                  <div class="event-content__right-col">
                    <div class="event-content__label_short">Request:&nbsp</div>
                    <div class="event-content__label">Vacation request:</div>
                    <div class="event-content__status">
                      ${item.status[0].toUpperCase() + item.status.substring(1).toLowerCase().replace('_', ' ')}
                    </div>
                  </div>
                </div>`,
        fontColor: '#FFFFFF',
        backColor: '#DDA866',
        borderRadius: '32px',
      }
    });
  }

  get eventsVacations(): Array<DayPilot.EventData> {
    return this.vacations.map(item => {
      return {
        id: item.id!,
        resource: item.employment.id!,
        item,
        start: new DayPilot.Date(item.startDate),
        end: new DayPilot.Date(item.endDate).addDays(1),
        text: '',
        html: `<div class="event-content__col">
                 ${this.$wss.getCurrent.type === WorkspaceType.PERSON ? item.employment.employer.name : item.employment.details.name} • ${item.startDate} - ${item.endDate} • ${item.paid ? 'Payed' : 'Not payed'} • ${item.duration}
               </div>`,
        fontColor: '#FFFFFF',
        backColor: new DayPilot.Date(item.endDate) < new DayPilot.Date(DayPilot.Date.today()) ? '#D1D1D1' : '#92CC8A',
        borderRadius: '32px',
      }
    });
  }

  get checkPreviousTime (): boolean {
    return this.selectedYear === this.years[0] && this.selectedMonth === 0;
  }

  get checkNextTime (): boolean {
    return this.selectedYear === this.years[this.years.length - 1] && this.selectedMonth === 11;
  }

  get stringSelectedDate (): string {
    const month: string = moment(this.selectedMonth + 1, 'M').format('MMMM');
    return month[0].toUpperCase() + month.substring(1) + ' ' + this.selectedYear.toString();
  }

  get getYears(): { text: string, value: number }[] {
    const currentYear = 2024;
    for (let year = currentYear; year <= currentYear + 12; year++) {
      this.years.push(year);
    }
    return this.years.map((item) => {
      return { text: String(item), value: item};
    });
  }

  get getMonths(): { text: string, value: string }[] {
    return moment.months().map((item, index) => {
      item = item[0].toUpperCase() + item.substring(1);
      return { text: item, value: index.toString() };
    });
  }

  get id(): number {
    return this.$wss.getCurrent.id;
  }

  get textTarget(): string {
    return this.$wss.getCurrent.type === WorkspaceType.PERSON ? 'request' : 'vacation';
  }

}
